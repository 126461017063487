//compare
function drawCheckboxes() {
    var $relatedCategoryDiv = $('#relatedCategories');
    $relatedCategoryDiv.empty();
    $('#CompareAddSuccess').empty();
    $('#CompareAddError').empty();
    var categoryId = $('#selectProductCategory option:selected').attr('id');
    var data = {"category_id": categoryId};
    var url = 'drawCheckBoxesCompare';
    var successCallback = function(result) {
        if (result.status) {
            var retval = result.retval;
            for (var relatedCategory in retval) {
                if (retval.hasOwnProperty(relatedCategory)) {
                    var name = retval[relatedCategory].name;
                    var checked = retval[relatedCategory].checked;
                    var checkedString = '';
                    if (checked) {
                        checkedString = ' checked="checked" ';
                    }
                    var checkbox = '<div class="checkbox">'
                        + '<label><input type="checkbox"' + checkedString
                        + ' id="' + relatedCategory + '">' +  name
                        + '</label></div>';
                    $relatedCategoryDiv.append(checkbox);
                }
            }
        }
    };
    var failCallback = function(xhr, status, error) {
        console.log(error);
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function addOrUpdateCompare() {
    var url = 'addOrUpdateCompare';
    var categoryId = $('#selectProductCategory option:selected').attr('id');
    var relatedCategoryIds = [];
    $('#relatedCategories input:checked').each(function() {
        relatedCategoryIds.push($(this).attr('id'));
    });
    var data = {"category_id": categoryId, "category_ids": relatedCategoryIds};

    var successCallback = function(result) {
        $('#CompareAddError').empty();
        if (result.status) {
            $('#CompareAddSuccess').empty().append('successfully saved');
            setTimeout(
                function()
                {
                    cleanForm();
                }, 3000);
        } else {
            $('#CompareAddSuccess').empty();
            $('#CompareAddError').empty().append('an error occurred');
        }
    };
    var failCallback = function(xhr, status, error) {
        $('#CompareAddSuccess').empty();
        $('#CompareAddError').empty().append('an error occurred');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

//compare API
/*
url = 'cleanCompare';
url = 'addToCompare';
url = 'getProductsInfoCompare';
url = 'removeFromCompare';
*/

// Плашка сравнения:
$('.compare_fixed_section .collapsed_btn .btn-primary').click(function(event) {
    $(this).closest('.collapsed_btn').addClass('compare_visible');
    $('.compare_fixed_section .products_choose_row').slideDown();
    return false;
});
$('.compare_fixed_section .collapsed_btn .close_products_choose_row').click(function(event) {
    $(this).closest('.collapsed_btn').removeClass('compare_visible');
    $('.compare_fixed_section .products_choose_row').slideUp();
    return false;
});

function clearHtmlCompareSection(){
    var emptyThemplate = 
        '<div class="product_item empty_product">'+
            '<span class="compare_product_datas">'+
                '<img src="" class="product_img">'+
                '<span class="product_name"></span>'+
            '</span>'+
            '<button class="remove_product_compare">&times;</button>'+
        '</div>';
    var fullProduct = $('.products_choose_row .full_product');
    for (var i = 0; i < fullProduct.length; i++) {
        fullProduct[i].remove();
        $('.products_choose_row .product_item').last().after(emptyThemplate);
    }
}

function updateCompareSection(img, name, id){    
    var emptyItem = $('.products_choose_row .empty_product').first();     
    emptyItem.attr('data-product-id', id);
    emptyItem.find('img').attr('src', img);
    emptyItem.find('.product_name').text(name);   
    emptyItem.addClass('full_product');
    emptyItem.removeClass('empty_product');    
}

function isEmpty(obj) {
  for (var key in obj) {
    // если тело цикла начнет выполняться - значит в объекте есть свойства
    return false;
  }
  return true;
}

function checkCompareProducts(){
    var drawUrl = '/getProductsInfoCompare';
        var newData = {};
        var checkSuccessCallback = function(result) {          
            if (isEmpty(result.retval)) {
               $('.compare_fixed_section').hide();
               $('.compare_fixed_section').attr('data-length', '0'); 
            }
            else{  
                var productsLength = Object.keys(result.retval).length;   
                $('.compare_fixed_section').attr('data-length', productsLength);         
                for (var key in result.retval) {                  
                  var id = key;                  
                  var productData = result.retval[key]; 
                  updateCompareSection(productData.photo, productData.model, id);
                  $('input[data-compare=' + id + ']').prop('checked', true);
                  $('.compare_fixed_section').show();
                }                
            }            
        };
        var checkFailCallback = function(err) {
            console.log(err);
        };
        ajaxCallPostAsync(drawUrl, newData, checkSuccessCallback, checkFailCallback);
}
checkCompareProducts();

function addOrDeleteCompare(id, action){
   clearHtmlCompareSection();   
   $('input[data-compare]').prop('checked', false);

   var data = {"product_id": id};

    if (action == 'addProduct') {      
      var url = '/addToCompare';        
    }
    else {
        var url = '/removeFromCompare';
    }
    var addCompareSuccessCallback = function(result) {
        checkCompareProducts();           
    };    
    var addCompareFailCallback = function(err) {
        console.log(err);
    };
    ajaxCallPostSync(url, data, addCompareSuccessCallback, addCompareFailCallback);
}

function clearCompareAll(){
    $('input[data-compare]').prop('checked', false);
    var url = '/cleanCompare';
    var data = {};
    var successCallback = function() {
        checkCompareProducts();
    };
    var failCallback = function() {};
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

// Чекбокс "сравнить" в категориях товаров
$('#content').on('change', 'input[data-compare]', function () {    
    var productId = $(this).data('compare');    
    if ($(this).is(':checked')) {
        var compareLength = $('.compare_fixed_section').attr('data-length'); 
        if (compareLength > 3) {
            alert('Можно сравнивать не более 4-х товаров!');            
        }
        addOrDeleteCompare(productId, 'addProduct' );
    } else {
        addOrDeleteCompare(productId, 'deleteProduct' );
    }
});

// Кнопка сравнить в карточке товара
$('#product').on('click', '.add_compare', function () {    
    var productId = $(this).data('compare');    
    addOrDeleteCompare(productId, 'addProduct' );
    return false;    
});
// Кнопка Х в товаре в плашке сравнения
$('body').on('click', '.remove_product_compare', function () {
    var removeProductId = $(this).closest('.product_item').data('product-id');
    addOrDeleteCompare(removeProductId, 'deleteProduct' );
});
$('.remove_product_compare_page').click(function(){
    var removeProductId = $(this).closest('.product_title_wrapp').attr('id');
    addOrDeleteCompare(removeProductId, 'deleteProduct' );
    document.location.reload(true);
});