//products test
function addTest() {
    $('#TestSuccess').empty();
    $('#TestError').empty();
    var category = $('#selectProductCategory').val();
    var question = {
        'order': $('#testQuestionNumber').val(),
        'text': $('#testQuestionText').val()
    };
    var answers = [];
    $('.answer-row').each(function() {
        var answerOrder = $('.test-answer-order', this).val();
        var answerText = $('.test-answer', this).val();
        if (0 === answerOrder.length || 0 === answerText.length) {
            return true;
        }
        var skipQuestions = $('.test-questions-skip', this).val();
        if (undefined === skipQuestions || 0 == skipQuestions || '000' === skipQuestions) {
            skipQuestions = '';
        }
        var productIds = $('.test-answer-products', this).val();
        if (undefined === productIds || 0 == productIds || '000' === productIds) {
            productIds = '';
        }
        var answer = {
            'order': answerOrder,
            'text': answerText,
            'skipQuestions': skipQuestions,
            'productIds': productIds
        };
        answers.push(answer);
    });
    var data = {
        'category': category,
        'question': question,
        'answers': answers
    };
    var url = 'saveTestToDb';
    var successMsg = 'successfully saved';
    var errorMsg = 'test was not saved successfully';
    testSaveOrChange(url, data, successMsg, errorMsg);
}

function getActiveCategoryQuestions(mainDivId) {
    var categoryId = $("#" + mainDivId + " #selectProductCategory option:selected").attr('id');
    $('#testActiveAnswers').empty();
    if (undefined !== categoryId) {
        var data = {
            'categoryId': categoryId
        };
        var url = 'getActiveQuestions';
        $("#TestError").empty();
        $("#TestSuccess").empty();
        var successCallback = function (result) {
            if (result.status) {
                var $questionsField = $('#' + mainDivId + ' #activeQuestions');
                $questionsField.empty();
                $questionsField.append('<option>Не выбрано</option>');
                var questionsData = result.retval;
                for (var i = 0; i < questionsData.length; ++i) {
                    var questionId = questionsData[i].id;
                    var question = questionsData[i].question;
                    var option = '<option id=' + questionId + '>' + question + '</option>';
                    $questionsField.append(option);
                }
            }
        };
        var failCallback = function() {}
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function testAnswerSave() {
    var questionId = $("#add_answer_div #activeQuestions option:selected").attr('id');
    var answerText = $('#add_answer_div #testAnswerText').val();
    var answerOrder = $('#add_answer_div #testAnswerOrder').val();
    var skipQuestions = $('#add_answer_div #testAnswerSkipQuestions').val();
    var answerProductIds = $('#add_answer_div #testAnswerProducts').val();
    if (undefined === skipQuestions || 0 == skipQuestions || '000' === skipQuestions) {
        skipQuestions = '';
    }
    if (undefined === answerProductIds || 0 == answerProductIds || '000' === answerProductIds) {
        answerProductIds = '';
    }
    var $testError = $('#TestError');
    if ((undefined === questionId) || ('' === answerText) || (0 >= answerOrder)) {
        $testError.empty().append('wrong parameters');
        return;
    }
    var data = {
        'questionId': questionId,
        'answerOrder': answerOrder,
        'answerText': answerText,
        'skipQuestions': skipQuestions,
        'productIds': answerProductIds
    };
    var url = 'testAnswerAdd';
    var successMsg = 'successfully saved';
    var errorMsg = 'test was not saved successfully';
    testSaveOrChange(url, data, successMsg, errorMsg);
}

function testSaveOrChange(url, data, successMsg, errorMsg) {
    var $testError = $('#TestError');
    var successCallback = function (result) {
        $testError.empty();
        if (result.status) {
            $('#TestSuccess').empty().append(successMsg);
            setTimeout(
                function()
                {
                    cleanForm();
                }, 3000);
        }
        else {
            $testError.append(errorMsg);
        }
        showSaveMessageLite();
    };
    var failCallback = function() {
        $testError.empty().append(errorMsg);
        showSaveMessageLite();
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function getQuestionData() {
    var questionId = $("#change_question_div #activeQuestions option:selected").attr('id');
    if (undefined === questionId) {
        return;
    }
    var data = {
        'questionId': questionId
    };
    var url = 'getQuestionData';
    var successCallback = function (result) {
        if (result.status) {
            $('#change_question_div #testQuestionOrder').val(result.retval.order);
            $('#change_question_div #testQuestionText').val(result.retval.question);
        }
    };
    var failCallback = function() {}
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function testQuestionChange() {
    var $questionField = $("#change_question_div #activeQuestions option:selected");
    var questionId = $questionField.attr('id');
    var newQuestionOrder = $('#change_question_div #testQuestionOrder').val();
    var newQuestionText = $('#change_question_div #testQuestionText').val();
    if (undefined === questionId || '' === newQuestionText || 0 >= newQuestionOrder) {
        return;
    }
    var data = {
        'questionId': questionId,
        'newQuestionOrder': newQuestionOrder,
        'newQuestionText': newQuestionText
    };
    var url = 'changeQuestion';
    var successMsg = 'successfully changed';
    var errorMsg = 'test was not changed successfully';
    testSaveOrChange(url, data, successMsg, errorMsg);
}

function testAnswersChange() {
    var $questionField = $("#change_answers_div #activeQuestions option:selected");
    var questionId = $questionField.attr('id');
    var answers = [];
    $('.active-answer').each(function() {
        var answerId = $(this).attr('id');
        var answerText = $('.test-answer-text', this).val() || '';
        var answerOrder = $('.test-answer-order', this).val() || 0;
        var skipQuestions = $('.test-questions-skip', this).val() || '';
        var answerProducts = $('.test-answer-products', this).val() || '';
        var answerObj = {
            'answerId': answerId,
            'answerText': answerText,
            'answerOrder': answerOrder,
            'skipQuestions': skipQuestions,
            'answerProducts': answerProducts
        };
        answers.push(answerObj);
    });
    var data = {
        'questionId': questionId,
        'answers': answers
    };
    var url = 'changeAnswers';
    var successMsg = 'successfully changed';
    var errorMsg = 'test was not changed successfully';
    testSaveOrChange(url, data, successMsg, errorMsg);
}

function testQuestionDelete() {
    var $questionField = $("#change_question_div #activeQuestions option:selected");
    var questionId = $questionField.attr('id');
    var questionText = $questionField.text();
    if (undefined === questionId) {
        return;
    }
    var data = {
        'questionId': questionId
    };
    var url = 'deleteQuestion';
    if (confirm('Вы уверены что хотите удалить вопрос: ' + questionText)) {
        var successCallback = function (result) {
            if (result.status) {
                alert('deleted');
                setTimeout(
                    function () {
                        cleanForm();
                    }, 3000);
            } else {
                alert('delete error');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('delete error');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function testAnswerDelete(answerId, answerText) {
    var data = {
        'answerId': answerId
    };
    var url = 'deleteAnswer';
    if (confirm('Вы уверены что хотите удалить ответ: ' + answerText)) {
        var successCallback = function (result) {
            if (result.status) {
                alert('deleted');
                setTimeout(
                    function () {
                        location.reload();
                    }, 3000);
            } else {
                alert('delete error');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('delete error');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function getTestAnswersDiv() {
    var $questionField = $("#change_answers_div #activeQuestions option:selected");
    var questionId = $questionField.attr('id');
    var $testActiveAnswersDiv = $('#testActiveAnswers');
    if (undefined === questionId) {
        $testActiveAnswersDiv.empty();
        return;
    }
    var data = {
        'questionId': questionId
    };
    var url = 'getActiveAnswers';
    var successCallback = function (result) {
        if (result.status) {
            var $addAnswerDiv = $("#change_answers_div #testActiveAnswers");
            var activeAnswers = result.retval;
            var size = (activeAnswers && activeAnswers.length) ? activeAnswers.length : 0;
            $testActiveAnswersDiv.empty();
            for (var i = 0; i < size; ++i) {
                var answerId = activeAnswers[i].id;
                var answerText = activeAnswers[i].answer;
                var answerOrder = activeAnswers[i].answer_order;
                var skipQuestions = activeAnswers[i].skip_questions;
                var answerProductIds = activeAnswers[i].product_ids;
                var answerRow = generateAnswerToChange(answerId, answerText, answerOrder, skipQuestions, answerProductIds);
                $addAnswerDiv.append(answerRow);
            }
        }
    };
    var failCallback = function (xhr, status, error) {};
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function generateAnswerToChange(answerId, answerText, answerOrder, skipQuestions, answerProductIds) {
    return '<div class="form-group answer-row bg-success active-answer" id="' + answerId + '">' +
        '<div class="row">' +
        '<div class="col-sm-6">' +
        '<label for="">Ответ ' + answerOrder + '</label>' +
        '<input type="text" class="test-answer-text" name="" id="" value="' + answerText + '" placeholder="Менее 100 метров" class="form-control test-answer"></div>' +
        '<div class="col-sm-3">' +
        '<label for="">Порядковый номер</label>' +
        '<input type="number" class="test-answer-order" min="0" max="10" name="" id="" value="' + answerOrder + '" placeholder="3" class="form-control test-answer-order">' +
        '</div>' +
        '<div class="col-sm-3">' +
        '<label for="">Пропуск вопросов</label>' +
        '<input type="text"  name="" id="" value="' + skipQuestions + '" placeholder="2,3,4" class="form-control test-questions-skip">' +
        '</div><br>' +
        '<div class="row">' +
        '<div class="col-sm-8">' +
        '<textarea name="" id="" class="form-control test-answer-products" placeholder="Товары через запятую с баллом 1 для этого варианта ответа">' + answerProductIds + '</textarea>' +
        '</div>' +
        '<div class="col-sm-4"><br>' +
        '<button class="btn btn-danger" onclick="testAnswerDelete(\'' + answerId + '\',\'' + answerText + '\')">Удалить ответ</button>' +
        '</div></div></div><br>';
}

//Выборка первого вопроса по тесту
function testDataInsert(questionName, answers, questionId, questionOrder) {
    $('.test_wrapp .question_name').text(questionName);
    $('.test_wrapp .answers_wrapp').html('');
    if (answers.length) {
        for (var i = 0; i < answers.length; i++) { 
            $('.test_wrapp .answers_wrapp').append('<label>'+
                  '<input type="radio" name="question_'+questionId+'_answer" id="answer_'+questionId+'_'+answers[i].answerId+'" data-answer-id="'+answers[i].answerId+'">'+
                  '<span>'+answers[i].answer+'</span>'+
                '</label>');
            $('.test_wrapp .question_wrapp').attr('data-question', questionOrder);
             $('.test_wrapp .question_points li[data-question-number="'+questionOrder+'"]').addClass('active');
        }
    }
}

function checkTestFirstQuestion(category){
    var drawUrl = '/getFirstQuestion';
    var datas = {
        categoryId: category
    };
    var checkSuccessCallback = function(result) {          
        if(!isEmpty(result.retval)){

            testDataInsert(result.retval.question, result.retval.answers, result.retval.questionId, result.retval.questionOrder)
            /*
            for (var key in result.retval) {
              var id = key;
              var questiontData = result.retval[key];
            }
            */
        }       
    };
    var checkFailCallback = function(err) {
        console.log(err);
    };
    ajaxCallPostAsync(drawUrl, datas, checkSuccessCallback, checkFailCallback);
}
function getTestNextQuestion(category, answer, QuestionOrder) {
    var drawUrl = '/getNextQuestion';
    var datas = {
        categoryId: category,
        answerId: answer,
        currentQuestionOrder: QuestionOrder
    };
    var checkSuccessCallback = function(result) {
        if(!isEmpty(result.retval) && result.retval.questionId){

            testDataInsert(result.retval.question, result.retval.answers, result.retval.questionId, result.retval.questionOrder)
            /*
            for (var key in result.retval) {
              var id = key;                  
              var questiontData = result.retval[key];
            } 
            */
        }   
        else {
            testCalculateResults(category);
        }
    };
    var checkFailCallback = function(err) {
        console.log(err);
    };
    ajaxCallPostAsync(drawUrl, datas, checkSuccessCallback, checkFailCallback);
}
function testCalculateResults(category) {
    var drawUrl = '/calculateResults';
    var datas = {
        categoryId: category,
    };
    var checkSuccessCallback = function(result) {          
        if(!isEmpty(result.retval)){

            //testDataInsert(result.retval.question, result.retval.answers, result.retval.questionId, result.retval.questionOrder)
            $('.test_wrapp .question_wrapp').hide();
            $('.test_wrapp .results_wrapp').show();
            $('.test_wrapp').addClass('test_results_show')

            for (var key in result.retval) {
              var resultROw = result.retval[key];
              $('.test_wrapp .results_wrapp .carousel_products').append('<div class="slide_item">'+
                '<a href="'+resultROw.link+'" class="link">'+
                  '<span class="img">'+
                      '<img src="'+ resultROw.photo +'" alt="'+resultROw.model+'">'+
                  '</span>'+
                  '<span class="item_title">'+ resultROw.model +'</span>'+
                '</a>'+
              '</div>')
            }
            $('.test_wrapp .results_wrapp .carousel_products').slick({
                slidesToShow: 4,
                infinite: true,
               // arrows:true,
                dots: true,
                slidesToScroll: 4,
                responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
            });
        }
    };
    var checkFailCallback = function(err) {
        console.log(err);
    };
    ajaxCallPostAsync(drawUrl, datas, checkSuccessCallback, checkFailCallback);
}
function checkTestQuestionCounts(category){
    var drawUrl = '/getQuestionsQuantity';
    var datas = {
        categoryId: category
    };
    var checkSuccessCallback = function(result) {  
        if (result.retval > 0) {
            $('.test_wrapp .question_points').attr('data-quantity', result.retval);
            for (var i = 0; i <= result.retval; i++) { 
                $('.test_wrapp .question_points').append('<li data-question-number="'+(i+1)+'"></li>');
            }
            if ($('div.test_wrapp').is(':visible')) {    
                checkTestFirstQuestion(catNum);
                //Проверяем нажат ли ответ и показываем кнопку к следующему вопросу
                $('body').on('change', '.test_wrapp .answers_wrapp input', function(event) {
                    //event.preventDefault();
                    if ($(this).prop('checked')) {
                        $(this).closest('.question_item').find('.btn_next_result').addClass('visible_btn');
                    }
                });
                $('body').on('click', '.test_wrapp .question_wrapp .btn_next_result .btn', function(event) {
                    event.preventDefault();
                    if ($(this).parent('.btn_next_result').hasClass('visible_btn')) {
                       var answerId = $('.test_wrapp .answers_wrapp input:checked').attr('data-answer-id');
                       var curQuestion = $('.test_wrapp .question_wrapp').attr('data-question');     
                       $(this).parent('.btn_next_result').removeClass('visible_btn');                  
                       getTestNextQuestion(catNum, answerId, curQuestion);
                       if (curQuestion == result.retval - 1) {
                           $(this).text('Показать результаты');
                       }
                    }
                });
            }
        }  
        else {
            $('.test_wrapp .question_wrapp').text('Вопросов нет!')
        }    
              
    };
    var checkFailCallback = function(err) {
        console.log(err);
    };
    ajaxCallPostAsync(drawUrl, datas, checkSuccessCallback, checkFailCallback);
}
// запуск всех функций по тесту только на странице с тестом
var catNum = $('.test_wrapp').data('test-category-id');
checkTestQuestionCounts(catNum);