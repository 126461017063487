$.cookieBar({
    message: '<div class="container cookie-bar-text"><div class="row"><div class="col-sm-4"><h3>' + $('#cookie-header-text').html() + '</h3></div><div class="col-sm-8"><p>' + $('#cookie-text').html() + '</p></div></div></div>',
    acceptText: '<div class="cookie-bar-btn"><span class="cookie-bar-btn-text">' + $('#cookie-close-text').html() + '</span><span class="glyphicon glyphicon-remove-sign"></span></div>'
});
// // About US parallax
// Token

if ($('#cookie-bar').is(':visible')) {
   $('body').addClass('cookie-bar-visivle');
   $('.cookie-bar-btn').click(function(event) {
       $('body').removeClass('cookie-bar-visivle');
   });
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
jQuery(document).ready(function () {
    jQuery('.parallax-layer').parallax({
        mouseport: jQuery("#about_parallax_port")
    });
});
// AJAX
$('[data-ajax]').each(function (index, element) {
    data_ajax_reload(element);
});
function data_ajax_reload(element) {
    $.ajax({
        url: $(element).data('ajax'),
        method: 'get',
        success: function (data) {
            $(element).html(data);
            checkcompared();
            countproducts(element);
        }
    });
}

function countproducts(element) {
    $.ajax({
        url: $(element).data('ajax-count'),
        method: 'get',
        success: function (data) {
            $('.products_count').html(data);
        }
    });
}

$('[data-ajax]').on('click', '.pagination a', function (e) {
    e.preventDefault();
    var parent = $(this).parents('[data-ajax]');
    parent.data('ajax', $(this).attr('href'));
    data_ajax_reload(parent);
});
//*** WYSZUKIWARKA ********************************************************

$('input[name="q"]').on('keyup', function () {
    uri = $(location).attr('pathname');
    query_data = $(this).val();
    if (query_data.length > 2) {
        console.log(query_data);
        $.ajax({
            url: '/ajax/search',
            method: 'POST',
            data: {query_data: query_data,
                lang: uri.split('/')[1],
            },
            success: function (msg) {
                // console.log(msg);
                $('.result').html(msg);
            }
        });
    }
    else{
        $('.result').html('');
    }

});
$('input[name="q2"]').on('keyup', function () {
    uri = $(location).attr('pathname');
    query_data = $(this).val();
    if (query_data.length > 2) {
        console.log(query_data);
        $.ajax({
            url: '/ajax/search',
            method: 'POST',
            data: {query_data: query_data,
                lang: uri.split('/')[1],
            },
            success: function (msg) {
                // console.log(msg);
                $('.result_search_404').html(msg);
            }
        });
    }
    else{
        $('.result_search_404').html('');
    }

});

$('#search').on('submit', function (e) {

    if ($('input[name="q"]').val() === '') {
        e.preventDefault();
    }

});
//*** POROWNAJ PRODUKTY ********************************************************


/*
// Checkboxy - listening
$('#content').on('change', 'input[data-compare]', function () {
    var productImg = $(this).closest('.product').find('figure img').attr('src');
    var productName = $(this).closest('.product').find('.description h3 a').text();
    var productId = $(this).data('compare');
    if ($(this).is(':checked')) {
        // addtocompare(productId, 'add');
        updateCompareSection(productImg, productName, productId, 'addCompare');
    } else {
// Usuwamy
        // addtocompare(productId, 'delete');
        updateCompareSection(productImg, productName, productId, 'delete');
    }
});
$('body').on('click', '.remove_product_compare', function () {
    var removeProductId = $(this).closest('.product_item').data('product-id');
    //addtocompare(removeProductId, 'delete');
    updateCompareSection('', '', removeProductId, 'delete');
    $('input[data-compare=' + removeProductId + ']').prop('checked', false);
});
*/
// $('.remove_product_compare').click(function(event) {
//     var removeProductId = $(this).closest('.product_item').data('product-id');
//     addtocompare(removeProductId, 'delete');
//     updateCompareSection('', '', removeProductId, 'delete');
//     $('input[data-compare=' + removeProductId + ']').prop('checked', false);
// });

// Przyciski - strona produktu
/*
$('a.add_compare[data-compare]').click(function (e) {
    addtocompare($(this).data('compare'), 'add');
    e.preventDefault();
});
*/
// Dodajemy/usuwamy do porównania
/*
function addtocompare(id, action)
{
    $.ajax({
        url: '/ajax/addtocompare',
        method: 'post',
        cache: false,
        data: {
            id: id,
            action: action
        },
        success: function (data) {
            if (Object.keys(data).length) {
                $('.compare_fixed_section').show();
                // $('.btn-compare .count').html(' (' + Object.keys(data).length + ')');
                // $('a.compare[data-compare=' + id + ']').show();
                // $('a.compare[data-compare=' + id + '] span.count').html(' (' + Object.keys(data).length + ')');
                // $('a.add_compare[data-compare=' + id + ']').hide();
            } else {
                $('.compare_fixed_section').hide();
            }
        }
    });
}
*/
// Zaznaczamy produkty on load
/*
function checkcompared() {
    $.ajax({
        url: '/ajax/addtocompare',
        method: 'post',
        cache: false,
        data: {},
        success: function (data) {
            if (Object.keys(data).length) {
                // $('.btn-compare').show();
                // $('.btn-compare .count').html(' (' + Object.keys(data).length + ')');
                // Zaznaczamy inputy
                $('.compare_fixed_section').show();
                for (i in data) {
                    $('input[data-compare=' + data[i] + ']').prop('checked', true);
                    $('a.compare[data-compare=' + data[i] + ']').show();
                    $('a.compare[data-compare=' + data[i] + '] span.count').html(' (' + Object.keys(data).length + ')');
                    $('a.add_compare[data-compare=' + data[i] + ']').hide();
                }
            } else {
                // $('.btn-compare').hide();
                $('.compare_fixed_section').hide();
            }
        }
    });
}
checkcompared();
*/
//*** FILTRACJA PRODUKTÓW ******************************************************
$('.filtracja').on('change', 'input[data-filter-id]', function () {
    if ($(this).is(':checked')) {
        addtofilter('add', $(this).data('filter-id'), $(this).data('filter-type'), $(this).data('filter-category'));
    } else {
// Usuwamy
        addtofilter('delete', $(this).data('filter-id'), $(this).data('filter-type'), $(this).data('filter-category'));
    }
});
function addtofilter(action, id, type, category_id)
{
    $.ajax({
        url: '/ajax/addtofilter',
        method: 'post',
        cache: false,
        data: {
            id: id,
            type: type,
            action: action,
            category_id: category_id,
        },
        success: function (data) {
            $('[data-ajax]').each(function (index, element) {
                if ($('.pagination').length > 0) {
                    $(element).data('ajax', $('.pagination  li:eq(1) a').attr('href'));
                }
                data_ajax_reload(element);
            });
        }
    });
}

if ($('[data-filter-id]').length > 0) {
    getfiltered();
}
function getfiltered()
{
    $.ajax({
        url: '/ajax/addtofilter',
        method: 'post',
        cache: false,
        data: {
            action: 'check',
        },
        success: function (data) {

            for (var category_id in data) {
                if (data.hasOwnProperty(category_id)) {

                    for (var type in data[category_id]) {
                        if (data[category_id].hasOwnProperty(type)) {

                            for (var id in data[category_id][type]) {
                                if (data[category_id][type].hasOwnProperty(id)) {
                                    $('[data-filter-type=' + type + '][data-filter-category=' + category_id + '][data-filter-id=' + id + ']').prop('checked', true);
                                }
                            }

                        }
                    }

                }
            }
        }
    });
}
//sklepy


// $('.networks li').click(function (e) {
// // alert($(this).data('network'));

//     deleteMarkers();
//     getStores($(this).data('network'));
//     $('.networks li').removeClass('active');
//     $(this).addClass('active');
// });

// function clearMarkers() {
//     setMapOnAll(null);
// }

// // Shows any markers currently in the array.
// function showMarkers() {
//     setMapOnAll(map);
// }


// Deletes all markers in the array by removing references to them.
function deleteMarkers() {
    clearMarkers();
    markers = [];
}
function setMapOnAll(map) {
    for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
    }
}

$('#category_instr').on('changed.bs.select', function (e, clickedIndex, newValue, oldValue) {
    getProductsInstructions(e.target.value);
});
$('#product_select').on('changed.bs.select', function (e, clickedIndex, newValue, oldValue) {
    getProductDetail(e.target.value);
});

function getProductsInstructions(cat_id)
{
    $('#product_det_cont').html('');
//alert(network);

    $.ajax({
        url: '/ajax/productinstructions',
        method: 'post',
        cache: false,
        data: {
            cat_id: cat_id
        },
        success: function (data) {

            $('#product_select').html(data);
            $("#product_select").selectpicker("refresh");
        }
    });
}

function getProductDetail(product_id)
{
    $.ajax({
        url: '/ajax/productdetail',
        method: 'post',
        cache: false,
        data: {
            product_id: product_id
        },
        success: function (data) {

            $('#product_det_cont').html(data);

        }
    });

}
$('.mob_filters_toggle').click(function(){
    $(this).closest('.sidebar').find('.aside_filters_wrapper').toggle('300');
    $(this).toggleClass('visible_filters');
    return false;
});

//*** WHERE TO BUY ************************************************************* - depecated

if( $('#myModal').length > 0 )
{
    $.ajax({
        url: 'https://plrss-data.where-to-buy.co/feeds/plrss/v1/' + $('#myModal').data('stock_code'),
        data: {
            'type' : 'json',
            'authorizationToken' : 'Z58QH8nvuxDQlHRDCC7M4Vhu5I0yAuDBFF4pmrEXRCRVtR2HFnJfCnMxS9W+cq7LsgsBMb1q0jMr73RJYYvbcGFd1TBaZDEngnXw9mFsPaU=',
            'tag' : 'Brandsite'
        },
        cache: false,
        success: function(data){
            var retailers = data.products[0].retailers;
            for(i in retailers){
                $('#buy-now-button').removeClass('hidden');
                var item = '<div class="row" style="padding-bottom: 10px;"><div class="col-sm-6 text-right"><img src="'+retailers[i].logo_url+'" alt=""></div><div class="col-sm-6">';
                if(retailers[i].instock == 'true'){
                    item += '<a href="'+ retailers[i].deeplink_url +'" nofollow target="_blank" class="btn btn-primary" onClick="ga(\'send\', \'event\', { eventCategory: \'where-to-buy\', eventAction: \'click\', eventLabel: \'Retailer click\'});">Купить</a>';
                }
                else{
                    item += '<span style="padding: 11px 2px; display: inline-block;">Недоступен</span>';
                }                
                item += '</div></div>';
                $('#myModal .modal-body').append(item);
            }
        },
        error: function(){
            console.log('404');
        }
    });
    
}



/*
ymaps.ready(function(){
    if( $('.wtbModal').length > 0 )
    {
        var id = $('.wtbModal').attr('modal-id');

        var longitude = ymaps.geolocation.longitude ? ymaps.geolocation.longitude : '55.753215';
        var latitude = ymaps.geolocation.latitude ? ymaps.geolocation.latitude : '37.622504';

        $.ajax({
            url: '/ajax/wtb-data/'+id+'/' + longitude +'/' + latitude,
            cache: false,
            success: function(data){
                console.log(data);
                if(data) {
                    $('.wtbModal').find('.modal-body').html(data + '<br><br>');
                    $('#wtb-' + id).removeClass('hidden');
                }
            },
            error: function(){
                console.log('404');
            }
        });

    }
});
*/