//addresses
function saveObjectAddress() {
    var objectType = $('#objectType option:selected').val();
    if ("center" === objectType) {
        return saveServiceCenters();
    } else if ("shop" === objectType) {
        return saveShops();
    }
    alert("Have to set center or shop");
    return false;
}

function saveServiceCenters() {
    var url = 'saveAddressServiceCenter';
    return saveAddress(url);
}

function saveShops() {
    var url = 'saveAddressStore';
    return saveAddress(url);
}

function saveAddress(url) {
    var country = $('#country option:selected').text();
    var city = $('#city').val();
    var coordinates = $('#coordinates').val();
    if ('' === country || '' === city || '' === coordinates) {
        alert("Have to set country, city and coordinates");
        return false;
    }
    var data = {
        "country": country,
        "city": city,
        "coordinates": coordinates,
        "region": $('#region').val(),
        "area": $('#area').val(),
        "street": $('#street').val(),
        "house": $('#house').val(),
        "objectName": $('#objectName').val(),
        "zip": $('#zip').val(),
        "additionalInfo": $('#additionalInfo').val()
    };
    var successCallback = function (result) {
        if (result.status) {
            alert('saved successfully');
            cleanForm();
            setTimeout(
                function () {
                    location.reload();
                }, 1000);
        } else {
            alert('error saving address');
        }
    };
    var failCallback = function (xhr, status, error) {
        alert('error saving address');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

//Front scripts
if ($('#map_container').length > 0) {
   var markers = [];
   var bounds;
    var infowindow = new google.maps.InfoWindow();
    var center = new google.maps.LatLng(55.758122, 37.6184673)

    var map = new google.maps.Map(document.getElementById('map_container'), {
        zoom: 12,
        //maxZoom: 12,
        mapTypeControlOptions: {
            mapTypeIds: ['styled_map']
        },
        center: center,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });


    var input = document.getElementById('input_place');
    var autocomplitOptions = {
      //types: ['(cities)'],
      componentRestrictions: {country: ['ru', 'by', 'kz', 'ua']}
    };
    var autocomplete = new google.maps.places.Autocomplete(input, autocomplitOptions);
    autocomplete.addListener("place_changed", fillInAddress);

    function fillInAddress() {
      // Get the place details from the autocomplete object.
      //очищаем маркеры
      const place = autocomplete.getPlace();
      var cityCountryArray = [];
      if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("Нет данных по этому адресу: '" + place.name + "'");
          return;
       }
        var componentMap = {
            country: 'country',
            locality: 'locality',
        };
        //Отбираем только город и страну из результатов
        for(var i = 0; i < place.address_components.length; i++){

            var types = place.address_components[i].types; // get types array of each component

            for(var j = 0; j < types.length; j++){ // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above

                  var component_type = types[j];

                  // check if this type is in your component map.If so that means you want this component

                  if(componentMap.hasOwnProperty(component_type)){

                    cityCountryArray.push(place.address_components[i]['long_name'])

                  }
            }
        }
        if (cityCountryArray.length > 0) {
            var adresslocation = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
            deleteMarkers();
            $('.details_adress_carousel .details_adress_carousel_wrapp').html('');
            bounds = new google.maps.LatLngBounds();
            if (cityCountryArray.length == 1) {
                cityCountryArray[1] = 'Россия';
            }
            if ($('#check_shop').prop('checked')) {
              getMapAdresses(cityCountryArray[1], cityCountryArray[0], adresslocation, 'getAddressStore');
              
            }
            else{
              getMapAdresses(cityCountryArray[1], cityCountryArray[0], adresslocation, 'getAddressServiceCenter');
            }

            //map.setCenter(place.geometry.location);
            
        }
    }

    $('.details_adress_carousel .details_open_btn').click(function () {
        if ($(this).hasClass('opened')) {
            $(this).removeClass('opened');
            $(this).closest('.details_adress_carousel').find('.details_adress_carousel_wrapp').removeClass('carousel_visible');
            $(this).find('b').text('Детали');
            $('.details_adress_carousel_wrapp').slick('unslick');
        }
        else{
            $(this).addClass('opened');
           $(this).closest('.details_adress_carousel').find('.details_adress_carousel_wrapp').addClass('carousel_visible');
           $(this).find('b').text('Свернуть');
           $('.details_adress_carousel_wrapp').slick({
                slidesToShow: 3,
                arrows:true,
                slidesToScroll: 1,
                infinite: false,
                prevArrow: '<button type="button" class="slick-prev">‹</button>',
                nextArrow: '<button type="button" class="slick-next">›</button>',
                responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                  }
                },
              ]
            });
        }
       return false;
    });
    $('body').on('click', '.details_adress_item', function () {
        var markerNum = $(this).attr('data-number');
        map.panTo((markers[markerNum].position));
    })
}


function getMapAdresses(country, city, adresslocation, api_url){
    $.ajax({
        //url: 'getAddressServiceCenter',
        url: api_url,
        headers: "charset=utf-8",
        data: {
            country: country,
            city: city
        },
        dataType: 'json',
        success: function (result) {
            if (result.retval.length > 0) {
                $('.details_adress_carousel .details_adress_carousel_wrapp').html('');
                var counter = 0;
                for (var key in result.retval) {
                  var resultROw = result.retval[key];
                  addMarker(resultROw);
                  drawAdressInfoCarousel(resultROw.city, resultROw.street, resultROw.house, resultROw.object_name, resultROw.coordinates, resultROw.zip, counter);
                  counter++;
                }
                find_closest_marker(adresslocation);
                $('.details_adress_carousel').show();
            }
            else{
                $('.details_adress_carousel').hide();
                $('.error_map_adress').html('<p>Нет данных по этому местоположению</p>');
                setTimeout(function (){
                  $('.error_map_adress').html('');
                }, 5000);
            }

        }
    });
}

function addMarker(point) {
    var coords = point.coordinates.split(', ');
    location2 = new google.maps.LatLng(Number(coords[0]), Number(coords[1]));
    var marker = new google.maps.Marker({
        position: location2,
        map: map,
        icon : "/images/site/map_icon_blue.png"
    });

    marker.addListener('click', function () {

        infowindow.close();
        infowindow.setContent('<div id="content">'
                + point.city + ', ' +  point.street
                + '<br>' + point.house + ', ' + (point.zip !== null && point.zip !== '' ? point.zip : '')
                + '<br>' + point.object_name
                + '</div>');
        infowindow.open(map, marker);
        map.panTo(marker.position);

    });

    markers.push(marker);
    bounds.extend(marker.position);
    //map.fitBounds(bounds);
}
function find_closest_marker(event) {
  var distances = [];
  var closest = -1;
  for (i = 0; i < markers.length; i++) {
    var d = google.maps.geometry.spherical.computeDistanceBetween(markers[i].position, event);
    distances[i] = d;
    if (closest == -1 || d < distances[closest]) {
      closest = i;
    }
  }
  //alert('Closest marker is: ' + markers[closest]);
  map.setCenter(markers[closest].position);
  map.setZoom(16);

}

function drawAdressInfoCarousel(city, street, house, name, coords, zip, counter) {
    $('.details_adress_carousel .details_adress_carousel_wrapp').append('<div class="details_adress_item" data-coordinates="'+coords+'" data-number="'+counter+'">'
                           +'<span class="map-marker"></span>'
                           +'<div class="adress_text">'
                               +'<p>'+city+', '+ street+'</p>'
                               +'<p>'+house+', '+ (zip !== null && zip !== '' ? zip : '')+'</p>'
                               +'<p>'+name+'</p>'
                          +' </div>'
                       +'</div>')
}
function deleteMarkers() {
  clearMarkers();
  markers = [];
}
// Removes the markers from the map, but keeps them in the array.
function clearMarkers() {
  setMapOnAll(null);
}

function getCountries() {
    var type = $('#objectType option:selected').val();
    if ("center" !== type && "shop" !== type) {
        alert("Have to set center or shop");
        return false;
    }
    var url = 'getCountries' + '?type=' + type;
    var successCallback = function (result) {
        if (result.status) {
            var $options = $('#countriesOptions');
            $options.empty();
            //var optionsStr = '<option>ничего не выбрано</option>';
            var optionsStr = '<option></option>';
            for (var i = 0; i < result.retval.length; ++i) {
                var country = result.retval[i];
                optionsStr += '<option value="' + country + '">' + country + '</option>';
            }
            $options.append(optionsStr);
        } else {
            alert('problem getting countries to type ' + type);
        }
    };
    var failCallback = function(xhr, status, error) {
        alert('problem getting countries to type ' + type);
    };
    ajaxCallGetSync(url, {}, successCallback, failCallback)
}

function getCities() {
    var type = $('#objectType option:selected').val();
    if ("center" !== type && "shop" !== type) {
        alert("Have to set center or shop");
        return false;
    }
    var country = $('#countriesOptions option:selected').val();
    if ('' === country) {
        alert("Have to set country");
        return false;
    }
    var url = 'getCities' + '?type=' + type + '&country=' + country;
    var successCallback = function (result) {
        if (result.status) {
            var $options = $('#citiesOptions');
            $options.empty();
            //var optionsStr = '<option>ничего не выбрано</option>';
            var optionsStr = '<option></option>';
            for (var i = 0; i < result.retval.length; ++i) {
                var city = result.retval[i];
                optionsStr += '<option value="' + city + '">' + city + '</option>';
            }
            $options.append(optionsStr);
        } else {
            alert('problem getting cities to type ' + type + ' and country ' + country);
        }
    };
    var failCallback = function(xhr, status, error) {
        alert('problem getting cities to type ' + type + ' and country ' + country);
    };
    ajaxCallGetSync(url, {}, successCallback, failCallback)
}

function getBranches() {
    var type = $('#objectType option:selected').val();
    if ("center" !== type && "shop" !== type) {
        alert("Have to set center or shop");
        return false;
    }
    var country = $('#countriesOptions option:selected').val();
    var city = $('#citiesOptions option:selected').val();
    if ('' === country || '' === city) {
        alert("Have to set country and city");
        return false;
    }
    var url = '';
    if ('center' === type) {
        url = 'getAddressServiceCenter';
    } else {
        url = 'getAddressStore';
    }
    url += '?country=' + country + '&city=' + city;
    var successCallback = function (result) {
        if (result.status) {
            var $branchesData = $('#branchesData');
            $branchesData.empty();
            var branchesStr = '';
            for (var i = 0, j = 1; i < result.retval.length; ++i, j++) {
                branchesStr += getBranchHtml(result.retval[i], j);
            }
            $branchesData.append(branchesStr);
        } else {
            alert('problem getting cities to type ' + type + ' and country ' + country);
        }
    };
    var failCallback = function(xhr, status, error) {
        alert('problem getting cities to type ' + type + ' and country ' + country);
    };
    ajaxCallGetSync(url, {}, successCallback, failCallback)
}

function getBranchHtml(branchData, index) {
    var id = branchData.id;
    return '<div class="panel panel-info" id="address_' + id + '">' +
        '  <div class="panel-heading" style="padding: 4px 15px;">' +
        '    <h3 style="margin-bottom: 0; margin-top: 0;"><p style="margin-bottom: 0;" class="text-primary"><strong>Адрес ' + index + '</strong></p></h3>' +
        '  </div>' +
        '  <div class="panel-body row">' +
        '    <div class="col-sm-4">' +
        '      <div class="form-group">' +
        '        <label>Страна</label>' +
        '        <input class="form-control" id="country" placeholder="Страна" type="text" value="' + branchData.country + '">' +
        '      </div>' +
        '    </div>' +
        '    <div class="col-sm-4">' +
        '      <div class="form-group">' +
        '        <label>Регион</label>' +
        '        <input class="form-control" id="region" placeholder="Регион такой-то" type="text" value="' + branchData.region + '">' +
        '      </div>' +
        '    </div>' +
        '    <div class="col-sm-4">' +
        '      <div class="form-group">' +
        '        <label>Область</label>' +
        '        <input class="form-control" id="area" placeholder="область такая-то" type="text" value="' + branchData.area + '">' +
        '      </div>' +
        '    </div>' +
        '    <div class="col-sm-4">' +
        '      <div class="form-group">' +
        '        <label>Город</label>' +
        '        <input class="form-control" id="city" placeholder="Москва" type="text" value="' + branchData.city + '">' +
        '      </div>' +
        '    </div>' +
        '    <div class="col-sm-4">' +
        '      <div class="form-group">' +
        '        <label>Улица</label>' +
        '        <input class="form-control" id="street" placeholder="Улица Пушкина" type="text" value="' + branchData.street + '">' +
        '      </div>' +
        '    </div><div class="col-sm-4">' +
        '      <div class="form-group">' +
        '        <label>Номер дома</label>' +
        '        <input class="form-control" id="house" placeholder="Д.1" type="text" value="' + branchData.house + '">' +
        '      </div>' +
        '    </div>' +
        '    <div class="col-sm-4">' +
        '      <div class="form-group">' +
        '        <label>Координаты на карте</label>' +
        '        <input class="form-control" id="coordinates" placeholder="55.3333333, 38.4444444" type="text" value="' + branchData.coordinates + '">' +
        '      </div>' +
        '    </div>' +
        '    <div class="col-sm-4">' +
        '      <div class="form-group">' +
        '        <label>Название объекта</label>' +
        '        <input class="form-control" id="objectName" placeholder="ООО и т.д." type="text" value="' + branchData.object_name + '">' +
        '      </div>' +
        '    </div>' +
        '    <div class="col-sm-4">' +
        '      <div class="form-group">' +
        '        <label>Индекс</label>' +
        '        <input class="form-control" id="zip" placeholder="1236544" type="text" value="' + branchData.zip + '">' +
        '      </div>' +
        '    </div>' +
        '    <div class="col-sm-12">' +
        '      <div class="form-group">' +
        '        <label>Дополнительная информация</label>' +
        '        <textarea name="" id="additionalInfo" class="form-control" placeholder="Здесь вот такая вот информация">'+ branchData.additional_info + '</textarea>' +
        '      </div>' +
        '    </div>' +
        '  </div>' +
        '  <div class="clearfix">' +
        '    <div class="col-sm-12">' +
        '      <button type="submit" class="btn btn-success" onclick="updateAddress(' + id + ')">Сохранить адрес</button>' +
        '      <button type="reset" class="btn btn-danger" onclick="deleteAddress(' + id + ')">Удалить адрес</button>' +
        '      <br> <br>' +
        '    </div>' +
        '  </div>' +
        '</div>';
}

function updateAddress(id) {
    var type = $('#objectType option:selected').val();
    if ("center" !== type && "shop" !== type) {
        alert("Have to set center or shop");
        return false;
    }
    var branchAddressIdName = 'address_' + id;
    var data = {
        "id": id,
        "country": $('#' + branchAddressIdName + ' #country').val(),
        "region": $('#' + branchAddressIdName + ' #region').val(),
        "area": $('#' + branchAddressIdName + ' #area').val(),
        "city": $('#' + branchAddressIdName + ' #city').val(),
        "street": $('#' + branchAddressIdName + ' #street').val(),
        "house": $('#' + branchAddressIdName + ' #house').val(),
        "coordinates": $('#' + branchAddressIdName + ' #coordinates').val(),
        "objectName": $('#' + branchAddressIdName + ' #objectName').val(),
        "zip": $('#' + branchAddressIdName + ' #zip').val(),
        "additionalInfo": $('#' + branchAddressIdName + ' #additionalInfo').val()
    };
    var url = '';
    var getAddressByIdUrl = '';
    if ("center" === type) {
        url = 'updateAddressServiceCenter';
        getAddressByIdUrl = 'getAddressServiceCenterById';
    } else {
        url = 'updateAddressStore';
        getAddressByIdUrl = 'getAddressStoreById';
    }
    var successCallback = function (result) {
        if (result.status) {
            alert('updated successfully');
        } else {
            alert('error updating address');
        }
    };
    var failCallback = function (xhr, status, error) {
        alert('error updating address');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function deleteAddress(id) {
    var type = $('#objectType option:selected').val();
    if ("center" !== type && "shop" !== type) {
        alert("Have to set center or shop");
        return false;
    }
    var url = '';
    if ("center" === type) {
        url = 'deleteAddressServiceCenter';
    } else {
        url = 'deleteAddressStore';
    }
    var data = {"id": id};
    var successCallback = function (result) {
        if (result.status) {
            alert('deleted successfully');
            setTimeout(
                function () {
                    $('#address_' + id).remove(); //removing an element from DOM
                }, 1000);
        } else {
            alert('error deleting address');
        }
    };
    var failCallback = function (xhr, status, error) {
        alert('error deleting address');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}