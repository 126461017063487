function collapseList(name) {
    $('.' + name + '-collapse').slideToggle('slow');
}

function loadMoreProducts(currentPage, lastPage, categoryId) {
    $('.category_content_wrapper').find('#loading').fadeIn();
    var filters = sessionStorage.getItem("filters");
    if (!filters){
        filters = {
            "techs": [],
            "colors": [],
            "vars": [],
            "numeric": [],
            "category_id": categoryId
        };
    } else {
        filters = JSON.parse(filters);
    }
    var url = '/api/ajax/filter-product?' + 'category_id=' + filters['category_id'];
    var data = {
      "category_id": filters['category_id'],
      "page": currentPage + 1, 
      "filters": filters,
      "html": true
    };

    if (currentPage + 1 == lastPage){
        $('.btn_show_more').css('display', 'none');
    }  

    var successCallback = function (result) {
      if (result.html) {
        var refresh = $(result.html).clone();

        refresh.each(function() {
            if(this.className == "products_container"){
                $('.products_container').append(this.innerHTML);
            }else if (this.className == 'pagination'){
                $('.pagination').empty().append(this);
            }else if (this.className == 'btn btn_show_more') {
                $('.btn_show_more').replaceWith(this)
            }
        })
      }
      $('.category_content_wrapper').find('#loading').fadeOut();
    };
    var failCallback = function (xhr, status, error) {
      var html = '<div class="well text-center">нет товаров</div>';
      $('#count_products').empty().append(0);
      $('.test').empty().append(html);
      $('.category_content_wrapper').find('#loading').fadeOut();
    };
    ajaxCallAsync('POST', url, data, successCallback, failCallback);
  }
  

function eraseFilters(categoryId) {
    $('.category_content_wrapper').find('#loading').fadeIn();

    eraseColors();
    eraseVars();
    eraseTechs();
    eraseNumeric();

    var numeric = getNumeric();

    var url = '/api/ajax/filter-product?' + 'category_id=' + categoryId;
    var data = {
        "category_id": categoryId,
        "filters":{
            "techs": [],
            "colors": [],
            "vars": [],
            "numeric": numeric
        },
        "html": true
    };
    sessionStorage.getItem("filters");

    var successCallback = function (result) {
        $('#count_products').empty().append(result.count);
        $('.row.products_wrapper').empty().append(result.html);
        $('.category_content_wrapper').find('#loading').fadeOut();

        $.each($("input[disabled='disabled']"), function(){
            $(this).removeAttr('disabled');
        });

        for (var i in result.disable_filters) {
            $("[itemid='" + result.disable_filters[i] + "']").attr('disabled', true);
        }

        $('#additional_filter').hide();

        $.each($("div[additional-data-id]"), function(){
            $(this).hide();
        })
    };
    var failCallback = function (xhr, status, error) {
        var html = '<div class="well text-center">нет товаров</div>';
        $('#count_products').empty().append(0);
        $('.row.products_wrapper').empty().append(html);
        $('.category_content_wrapper').find('#loading').fadeOut();
    };

    ajaxCallAsync('POST', url, data, successCallback, failCallback());
}

function eraseColors() {
    $.each($("input[name='colors']:checked"), function(){
        $(this).prop('checked', false);
    });
}

function eraseTechs() {
    $.each($("input[name='techs']:checked"), function(){
        $(this).prop('checked', false);
    });
}

function eraseVars() {
    $(".attributes_div").find("div").each(function() {
        $.each($("div[id='" + this.id + "'] input[name='attrs']:checked"), function(){
            $(this).prop('checked', false);
        });
    });
}

function eraseNumeric() {
    $("#slider_div .slider_class").each(function(){
        var slider = $("div[id='" + this.id + "'] .filter_sliders");

        var sliderMin = slider.attr('data-slider-min');
        var sliderMax = slider.attr('data-slider-max');
        var defaultValue = sliderMin + ',' + sliderMax;

        $("div[id='" + this.id + "'] .slider-track-low").css('left', '0px').css('width', '0%');
        $("div[id='" + this.id + "'] .slider-selection").css('left', '0px').css('width', '100%');
        $("div[id='" + this.id + "'] .slider-track-high").css('right', '0px').css('width', '0%');

        $("div[id='" + this.id + "'] .tooltip-main").css('left', '50%');
        $("div[id='" + this.id + "'] .tooltip-min").css('left', '0%');
        $("div[id='" + this.id + "'] .tooltip-max").css('left', '100%');

        $("div[id='" + this.id + "'] .tooltip-main > .tooltip-inner").text(sliderMin + ' : ' + sliderMax);
        $("div[id='" + this.id + "'] .tooltip-min > .tooltip-inner").text(sliderMin);
        $("div[id='" + this.id + "'] .tooltip-max > .tooltip-inner").text(sliderMax);

        $("div[id='" + this.id + "'] .min-slider-handle").attr('aria-valuenow', sliderMin).css('left', '0%');
        $("div[id='" + this.id + "'] .max-slider-handle").attr('aria-valuenow', sliderMax).css('left', '100%');

        slider.attr('value', defaultValue).attr('data-value', defaultValue);
    });
}

function getPossibleFilters() {
    var category = $('#selectFilterCategory').val();
    if ('' === category) {
        return;
    }
}

function getProductsByFilters(categoryId) {
    $('.category_content_wrapper').find('#loading').fadeIn();

    //colors
    var colorsIds = getColorIds();
    //techs
    var techIds = getTechIds();
    //attributes
    var vars = getVars();
    //sliders
    var numeric = getNumeric();
    var url = '/api/ajax/filter-product?' + 'category_id=' + categoryId;
    var data = {
        "category_id": categoryId,
        "filters":{
            "techs": techIds,
            "colors": colorsIds,
            "vars": vars,
            "numeric": numeric
        },
        "html": true
    };
    if (window.sessionStorage) {
        const filtersObj = {
            "category_id": categoryId,
            "techs": techIds,
            "colors": colorsIds,
            "vars": vars,
            "numeric": numeric
        };
        sessionStorage.setItem("filters", JSON.stringify(filtersObj));
    }
    var successCallback = function (result) {
        $('#count_products').empty().append(result.count);
        $('.row.products_wrapper').empty().append(result.html);
        $('.category_content_wrapper').find('#loading').fadeOut();

        $.each($("input[disabled='disabled']"), function(){
            $(this).removeAttr('disabled');
        });

        for (var i in result.disable_filters) {
            $("[itemid='" + result.disable_filters[i] + "']").attr('disabled', true);
        }

        if (result.additional_filters.length > 0) {
            $('#additional_filter').show();

            $.each($("div[additional-data-id]"), function(){
                $(this).hide();
                $(this).next('.attributes_div').hide();
            });

            for (var j in result.additional_filters) {
                $("[additional-data-id='" + result.additional_filters[j] + "']").show();
                $(".list_opened[additional-data-id='" + result.additional_filters[j] + "']").next('.attributes_div').show();
            }
        } else {
            $('#additional_filter').hide();

            $.each($("div[additional-data-id]"), function(){
                $(this).hide();
            });
        }
    };
    var failCallback = function (xhr, status, error) {
        var html = '<div class="well text-center">нет товаров</div>';
        $('#count_products').empty().append(0);
        $('.row.products_wrapper').empty().append(html);
        $('.category_content_wrapper').find('#loading').fadeOut();
    };
    $.ajax({
        type: "POST",
        url: url,
        data: data,
        dataType: 'json',
        async: true,
        success: successCallback,
        error: failCallback
    });
}

function getColorIds() {
    var colorsIds = [];
    $.each($("input[name='colors']:checked"), function(){
        colorsIds.push($(this).attr('id'));
    });
    return colorsIds;
}

function getTechIds() {
    var techIds = [];
    $.each($("input[name='techs']:checked"), function(){
        if ($(this).parent().parent().parent().parent().parent().css('display') === 'block') {
            techIds.push($(this).attr('id'));
        }
    });
    return techIds;
}

function getVars() {
    var vars = [];
    var attrIds = [];
    $(".attributes_div").find("div").each(function() {
        if ($(this).parent().parent().css('display') === 'block') {
            attrIds.push(this.id);
        }
    });
    for (var i in attrIds) {
        var attrName = attrIds[i];
        var selected = [];
        $.each($("div[id='" + attrName + "'] input[name='attrs']:checked"), function(){
            selected.push($(this).attr('id'));
        });
        if (0 === selected.length) {
            continue;
        }
        var obj = {"name":attrName, "selected":selected};
        vars.push(obj);
    }
    return vars;
}

function getNumeric() {
    var sliderIds = [];
    $("#slider_div .slider_class").each(function(){
        if ($(this).parent().parent().parent().parent().css('display') === 'block') {
            sliderIds.push(this.id);
        }
    });
    var numeric = [];
    for (var j in sliderIds) {
        var idName = sliderIds[j];
        var values = $("div[id='" + idName + "'] .filter_sliders").val();
        var valuesArray = values.split(',');
        var min = parseFloat(valuesArray[0]);
        var max = parseFloat(valuesArray[1]);
        var obj2 = {"name":idName, "min_value":min, "max_value":max};
        numeric.push(obj2);
    }
    return numeric;
}

function setColorIds(colorsIds) {
    $("input[name='colors']").prop("checked", false);
    for(var i in colorsIds){
        $("#" + colorsIds[i]).prop("checked", true);
    }
}

function setTechIds(techIds) {
    $("input[name='techs']").prop("checked", false);
    for(var i in techIds){
        $("#" + techIds[i]).prop("checked", true);
    }
}

function setVars(vars) {
    $(".attributes_div input[name='attrs']").prop("checked", false);
    for(var i in vars){
        var attrName = vars[i].name;
        var selected = vars[i].selected;
        for(var j in selected){
            $("#" + attrName + " #" + selected[j]).prop("checked", true);
        }
    }
}

function setNumeric(numeric) {
    for(var i in numeric){
        var idName = numeric[i].name;
        var min = numeric[i].min_value;
        var max = numeric[i].max_value;
        var values = min.toString() + "," + max.toString();
        $("div[id='" + idName + "'] .filter_sliders").val(values);
    }
}

function unsetColorIds() {
    $("input[name='colors']").prop("checked", false);
}

function unsetTechIds() {
    $("input[name='techs']").prop("checked", false);
}

function unsetVars() {
    $(".attributes_div input[name='attrs']").prop("checked", false);
}

function unsetNumeric() {
    // Assuming there is no need to unset the numeric range sliders
    // as they do not have a "checked" state
}

// Где купить WTB
$(document).ready(function($) {
    if ($('li').is('.wtb_buy_btn')) {
        var id = $('.product_id');
        var model = $('#model_value').text();
        var wtbBtnBuy = $('.wtb_buy_btn').children('a');
        var WTBcount = 0;

        console.log(id.length);

        var url = '/api/where-to-buy';
        
        if( id.length > 1 ){
            $('.product_id').each( function() {
                if($(this).attr("id") != ''){
                var data = {'id':$(this).attr("id"), 'model':$(this).attr("model")};
                console.log("DATA:", data);
                var successCallback = function(data) {
                    if (data.data.length !== 0) {
                        for (var i = 0; i < data.data.length; i++) {
                            console.log("TRY:", WTBcount);
                            $('.wtbModalNew').find('.modal-body'+$(id[WTBcount]).attr('id')).append('<a href="'+data.data[i].link+'" target="_blank"><img src="'+data.data[i].logo+'" alt="'+data.data[i].name+'"></a>')
                        }
                        WTBcount++;
                        wtbBtnBuy.fadeIn();
        
                    } else {
                        WTBcount++;
                        console.log('no wtb shops')
                    }
                };
                var failCallback = function (xhR, err) {
                    console.log(xhR, err);
                };
                ajaxCallSync('POST', url, data, successCallback, failCallback);
                }
            })
            
        } else {
            var data = {'id':id.attr('id'), 'model':model};
            var successCallback = function(data) {
                if (data.data.length !== 0) {
                    console.log("TRY:", $('.wtbModalNew').find('.modal-body'));
                    for (var i = 0; i < data.data.length; i++) {
                        $('.wtbModalNew').find('.modal-body').append('<a href="'+data.data[i].link+'" target="_blank"><img src="'+data.data[i].logo+'" alt="'+data.data[i].name+'"></a>')
                    }
                    wtbBtnBuy.fadeIn();
    
                } else {
                    console.log('no wtb shops')
                }
            };
            var failCallback = function (xhR, err) {
                console.log(xhR, err);
            };
            ajaxCallSync('POST', url, data, successCallback, failCallback);
        }
        
    }

    var originalVal;
    var categoryFilter = $('#filters').attr("data-category-id");

    $("#slider_div .slider_class").each(function(){
        var rangeSlide = $(this).find('.filter_sliders')
        rangeSlide.bind("slideStart", function (e){
            originalVal = rangeSlide.val();
        });
        rangeSlide.bind("slideStop", function (e){
            var newVal = rangeSlide.val();
            if(originalVal !== newVal) {
                getProductsByFilters(categoryFilter);
            }
        });
    });

    var filterUrl = '/api/ajax/disable-filter?' + 'category_id=' + categoryFilter;
    var successCallback = function (result) {
        $.each($("input[disabled='disabled']"), function(){
            $(this).removeAttr('disabled');
        });

        for (var i in result.disable_filters) {
            $("[itemid='" + result.disable_filters[i] + "']").attr('disabled', true);
        }
    };
    if ($('#filters').length > 0) {
       $.ajax({
            type: "POST",
            url: filterUrl,
            data: {"category_id": categoryFilter},
            dataType: 'json',
            async: true,
            success: successCallback,
        }); 
    }
});