//banners
function getExistingBannersForChange() {
    var category = $("#selectExistingBannersCategory option:selected" ).text();
    if ('' === category) {
        return;
    }
    var url = 'getExistingBannersForChange';
    var data = {"category":category};
    var successCallback = function(result) {
        $('#ChangeBannersError').empty();
        $('#bannersTableBody').empty()
        if (result.status) {
            var retval = result.retval;
            for(var i in retval) {
                var string = '';
                var id = retval[i].id;
                var image = retval[i].image;
                var name = retval[i].name;
                var link = retval[i].link;
                var isActive = retval[i].is_active;
                if (isActive) {
                    string += '<tr id="' + id + '">';
                } else {
                    string += '<tr class="dis_product" id="' + id + '">';
                }
                string += '<td>' + id + '</td>';
                string += '<td>' + image + '</td>';
                string += '<td>' + name + '</td>';
                string += '<td>' + link + '</td>';
                var buttons = drawBannersActivationButtons(id, name, isActive);
                string += '<td class="btns_wrapp" id="btns-wrapper' + id + '">' + buttons;
                $('#bannersTableBody').append(string);
            }
        }
    };
    var failCallback = function() {
        $('#ChangeBannersSuccess').empty();
        $('#ChangeBannersError').empty().append('problem getting info');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function drawBannersActivationButtons(id, name, isActive) {
    var buttons = '';
    name = '\'' + name + '\'';
    if (isActive) {
        buttons += '<button type="button" class="btn btn-success" disabled title="Активировать"><span class="glyphicon glyphicon-ok"></span></button>';
        buttons += '<button type="button" class="btn btn-danger" onclick="deactivateBanner(' + id + ',' + name + ')" title="Удалить (деактивировать)"><span class="glyphicon glyphicon-remove"></span></button>';
    } else {
        buttons += '<button type="button" class="btn btn-success" onclick="activateBanner(' + id + ',' + name + ')" title="Активировать"><span class="glyphicon glyphicon-ok"></span></button>';
        buttons += '<button type="button" class="btn btn-danger" disabled title="Удалить (деактивировать)"><span class="glyphicon glyphicon-remove"></span></button>';
    }
    return buttons;
}

function activateBanner(id, name) {
    var url = 'activateBanner';
    var data = {"id":id};

    if (confirm('Вы уверены что хотите активировать баннер?')) {
        var successCallback = function (result) {
            if (result.status) {
                alert('activated');
                var buttons = drawBannersActivationButtons(id, name, true);
                $('#' + id).removeClass('dis_product');
                $('#btns-wrapper' + id).empty().append(buttons);
            } else {
                alert('error activating');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('error activating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function deactivateBanner(id, name) {
    var url = 'deactivateBanner';
    var data = {"id":id};

    if (confirm('Вы уверены что хотите деактивировать баннер?')) {
        var successCallback = function(result) {
            if (result.status) {
                alert('deactivated');
                var buttons = drawBannersActivationButtons(id, name, false);
                $('#' + id).addClass('dis_product');
                $('#btns-wrapper' + id).empty().append(buttons);
            } else {
                alert('error deactivating');
            }
        };
        var failCallback = function(xhr, status, error) {
            alert('error deactivating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}